import { Flex } from "antd-mobile";
import { Icon } from "components/basic";
import React, { memo, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { isCommunityAdmin } from "screens/Account/actions";
import { canIDeleteComment } from "screens/Community/Post/action";
import { getImageUrl, richTextFilter, timeInference } from "utils/util";

import "./index.scss"

interface ContentCommentProps {
  comment: any; // 评论信息
  floor: number; // 楼层
  onBanned?(user: any): void; // 禁言用户的回调函数，返回被禁言的用户信息
  onDelete?(comment: any): void; // 删除评论的会点函数， 返回被删除的评论信息
  onClick?(comment: any): void; // 点击评论的处理
}

const ContentComment: React.FC<ContentCommentProps> = (props) => {
  const history = useHistory();
  const { comment, floor, onBanned, onDelete, onClick } = props;

  const [bannedCount , setBannedCount] = useState(1);


  /**
   * 回复评论
   *
   * * 如果用户被禁言，无法回复
   * * 如果目标评论被删除，无法回复
   */
  const handleCommentClick = useCallback(
    (comment: any) => {
      onClick && onClick(comment);
    },
    [onClick]
  );

  const memberBannedComponent = useCallback((userInfo) => {
    if(isCommunityAdmin() && userInfo.is_banned !== 1 && onBanned){
      return <Flex
      style={{ padding: "3px 5px" }}
      align="center"
      onClick={(e) => {
        e.stopPropagation();
        if(bannedCount > 0){
          onBanned(userInfo);
          let bCount = bannedCount;
          setBannedCount(() => bCount++ )
        }
      }}
    >
      <Icon size="18" color="#999">micDisable</Icon>
    </Flex>;
    }
    return <></>
  },[bannedCount, onBanned]);


  // 评论被删除则不显示
  if (!comment || comment.is_delete === 1) return null;
  const { user_info, sub_comment_list } = comment;
  const filterSubComments = sub_comment_list.filter(
    (item) => item && item.refer_user_info && item.is_delete !== 1
  );

  /// 返回评论块
  return (
    <div className="component-content-comment">
      <Flex
        className="user-info"
        onClick={() => {
          history.push(`/member/${comment.member_id}`);
        }}
      >
        <img
          className="avatar"
          src={getImageUrl(user_info.member_avatar)}
          alt=""
        />
        <Flex.Item className="nickname">{user_info.nick_name}</Flex.Item>
        <div>{floor}楼</div>
      </Flex>
      <div style={{ paddingLeft: "37px" }}>
        <div
          className="content"
          onClick={(e) => {
            e.stopPropagation();
            handleCommentClick(comment);
          }}
        >
          {richTextFilter(comment.comment_content)}
        </div>
        <Flex className="status">
          <Flex.Item>{timeInference(comment.comment_addtime)}</Flex.Item>

          {/* 禁言按钮 */}
          {memberBannedComponent(comment.user_info)}

          {/* 删除评论按钮 */}
          {canIDeleteComment(comment) && onDelete && (
            <Flex
              style={{ padding: "3px 5px" }}
              align="center"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(comment);
              }}
            >
              <Icon size="18">empty_fill</Icon>
            </Flex>
          )}
        </Flex>

        {/* 过滤后的子评论 */}
        {filterSubComments.length > 0 && (
          <div className="comment-children">
            {filterSubComments.map((item) => {
              if (!item.refer_user_info) return null;
              return (
                <div
                  key={`comment-children-${item.comment_id}`}
                  className="comment-child"
                >
                  <div className="comment-child__header">
                    <span className="child-nickname">
                      {item.user_info.nick_name}
                    </span>
                    <span style={{ padding: "0 3px" }}>回复</span>
                    <span className="child-nickname">
                      {item.refer_user_info.nick_name}
                    </span>
                    <span>：</span>
                  </div>
                  <div
                    className="comment-child__body"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCommentClick(item);
                    }}
                  >
                    {richTextFilter(item.comment_content)}
                  </div>
                  <Flex className="comment-child__footer">
                    <Flex.Item>{timeInference(item.comment_addtime)}</Flex.Item>

                    {/* 禁言按钮 */}
                    {memberBannedComponent(item.user_info)}

                    {/* 删除评论按钮 */}
                    {canIDeleteComment(comment) && onDelete && (
                      <Flex
                        style={{ padding: "3px 5px" }}
                        align="center"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDelete(item);
                        }}
                      >
                        <Icon size="18">empty_fill</Icon>
                      </Flex>
                    )}
                  </Flex>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ContentComment);
