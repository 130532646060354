import { Carousel, Flex } from "antd-mobile";
import React, { useCallback, useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import { useHistory } from "react-router";
import { handleAdvClick } from "utils/ddhyAdRender";
import Icon from "../Icon";

import './index.scss';

interface Props {
  data: any;
  viewWidth?: number;
  dataClassName?: string;
  type?: string;
}

/**
 *
 * 组装广告
 * ap_class 广告类型 7单图片 1多图片 2单文本 3多文本 4单图文 5多图文 6轮播
 */
 const AdvCardView: React.FC<Props> = (props) => {
  const history = useHistory();

  const { data, viewWidth = 0, dataClassName='', type = 'ap' } = props;
  const { ap_code, ap_class, ap_size_type, ap_ratio, ap_width, ap_height } = data;

  const [apWidth, setApWidth] = useState(0);
  const [apHeight, setApHeight] = useState(0);

  let { adv_list } = data;
  let size_type = ap_size_type;
  if(type === 'adv'){
    adv_list = [data];
    size_type = data.ap_size;
  }


  // 屏幕宽度
  // const screenWidth = window.screen.availWidth;
  // 轮播图自动切换间隔(ms)
  const autoplayInterval = 5000;

  useEffect(() =>{
    const computeScreenSize = () => {
      // 计算广告的尺寸
      let _apWidth = window.screen.availWidth;
      if(viewWidth > 0){
        _apWidth = viewWidth;
      }
      let _apHeight = 0;
      if(size_type === 1){
        let ratio_arr = ap_ratio.split(':');
        let _ratio_width = parseInt(ratio_arr[0]);
        let _ratio_height = parseInt(ratio_arr[1]);
        _apHeight = _apWidth * _ratio_height / _ratio_width;
      } else {
        _apWidth = ap_width;
        _apHeight = ap_height;
      }

      setApWidth(_apWidth);
      setApHeight(_apHeight);
    }
    computeScreenSize();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  // 点击处理
  const handleAdClick = useCallback((item: any) => {
    handleAdvClick({
      link_type: item.link_type,
      link_obj: item.link_obj,
      obj_id: item.link_obj_id,
      invite_code: item.invite_code,
      link_url: item.link_url,
      youzan_url: item.youzan_url
    }, history);
  },[history]);

  // 渲染单图&多图&轮播广告
  const getMultiImage = useCallback(() => {
    return adv_list.map((item: any, index: number) => {
      return (
        <div key={ap_code+'-'+index} className={dataClassName} style={[1,6,7].includes(ap_class)? {
          display:'block',
          borderRadius: '10px',
          maxWidth: `${apWidth}px`,
          height: `${apHeight}px`,
          background: `url(${item.adv_image}) center center no-repeat`,
          backgroundSize: `cover`,
        }:{}} onClick={(e) => {
          e.stopPropagation();
          handleAdClick(item);
        }}>
        </div>
      );
    });
  },[adv_list, apHeight, apWidth, ap_class, ap_code, dataClassName, handleAdClick]);

  // 渲染多图文广告
  const renderMultiImgAndText = useCallback(() => {
    return adv_list.map((item: any, index: number) => {
      let imageList = [];
      let adv_image: string = item.adv_image;
      if(adv_image){
        imageList = adv_image.split(',');
      }
      return (
        <div key={ap_code+'-'+index} className="multi-img-text-card" onClick={() => handleAdClick(item)}>
          <div className="card-header Text-Line__2">{item.adv_title}</div>
          <div className="card-content Text-Line__2">{item.adv_contents}</div>
          <LazyLoad>
            <Flex className="card-images">
              {imageList.map((img: string, idx: number) => {
                return (
                  <Flex.Item
                    key={ap_code+'-'+index+'-img-'+idx}
                    className="img"
                    style={{
                      height: "4.5rem",
                      background: `url(${img}) center center no-repeat`,
                      backgroundSize: "cover",
                    }}
                    ></Flex.Item>
                );
              })}
            </Flex>
          </LazyLoad>
          <Flex className="card-footer">
            <div><Icon size="16">eye</Icon> {item.click_num}</div>
            <div style={{
              marginLeft:"30px"
            }}><Icon size="16">liuyan</Icon> 0</div>
          </Flex>
        </div>
      );
    });
  },[adv_list, ap_code, handleAdClick]);

  // 处理单文本广告
  const renderSingleText = useCallback(() => {},[]);
  // 处理多文本广告
  const renderMultiText = useCallback(() => {
    return <div className="multi-text-card">
      {adv_list.map((item: any, index: number) => {
        return <div key={ap_code+'-'+index} className={"text-item " + dataClassName} onClick={(e) => {
          e.stopPropagation();
          handleAdClick(item);
        }}>
          {item.adv_title}
        </div>;
      })}
    </div>;
  },[adv_list, ap_code, dataClassName, handleAdClick]);

  // 渲染轮播广告
  const renderCarousel = useCallback(() => {
    if(adv_list.length > 1){
      return <Carousel autoplay autoplayInterval={autoplayInterval}
        infinite
        dotStyle={{
          width: "10px",
          height: "2px",
          borderRadius: "0",
          background: "#ccc",
          marginBottom: "3px",
        }}
        dotActiveStyle={{
          width: "10px",
          height: "2px",
          borderRadius: "0",
          background: "#0099ff",
          marginBottom: "3px",
        }}
        frameOverflow = "visible"
        style={{ height: `${apHeight}px` }}
      >
        {getMultiImage()}
      </Carousel>
    }else{
      return getMultiImage();
    }

  }, [adv_list.length, apHeight, getMultiImage]);




  // 处理广告渲染
  switch(ap_class){
    case 7:
    case 1:
      return getMultiImage();
    case 2:
      return renderSingleText();
    case 3:
      return renderMultiText();
    // case 4:
    case 5:
      return renderMultiImgAndText();
    case 6:
      return renderCarousel();
  }
};

export default AdvCardView;
