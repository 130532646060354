import React from "react";
import { Flex } from "antd-mobile";
import { Icon } from "components/basic";
import "./index.scss";
import { getImageUrl } from "utils/util";
import { formatNumber } from "utils/number";
import { useHistory } from "react-router-dom";

const TopicItem: React.FC<{
  topic: DDHY.SNSTopic;
  onClick?(topic: DDHY.SNSTopic): void;
}> = ({ topic, onClick }) => {
  const history = useHistory();
  const LabelWithIcon = (props: any) => {
    return (
      <Flex align="center" style={{ marginRight: 12 }}>
        <Icon style={{ fontSize: "1.4em", marginRight: 2 }}>{props.icon}</Icon>
        <span>{props.children}</span>
      </Flex>
    );
  };

  return (
    <div
      className="Component-Topic"
      onClick={() => {
        if (onClick) {
          onClick(topic);
        } else {
          history.push(`/topic/${topic.topic_id}`);
        }
      }}
    >
      <Flex align="stretch">
        <div className="Topic-Image" style={{
          backgroundImage: "url(" + getImageUrl(topic.topic_icon) + ")"
        }}>
        </div>
        <Flex.Item>
          <Flex
            direction="column"
            justify="between"
            align="start"
            style={{ height: "100%" }}
          >
            <div className="Topic-Title">{topic.topic_name}</div>
            <Flex className="Topic-Footer" justify="start">
              <LabelWithIcon icon="liuyan">
                {topic.post_count}个回答
              </LabelWithIcon>
              {topic.hots_num && (
                <LabelWithIcon icon="activity">
                  {formatNumber(topic.hots_num)}热度
                </LabelWithIcon>
              )}
            </Flex>
          </Flex>
        </Flex.Item>
      </Flex>
    </div>
  );
};

export default TopicItem;
