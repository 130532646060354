import React, { useCallback, useEffect, useState } from "react";

import "./index.scss";
import mainApi from "api/main";
import { AdvCardView } from "components/basic";
import { useHistory } from "react-router-dom";

import functionTrainingIcon from "./assets/icon/function_trianing.png";
import functionDoctorIcon from "./assets/icon/function_doctor.png";
import functionSpecialIcon from "./assets/icon/function_special.png";
import functionHospitalIcon from "./assets/icon/function_hospital.png";
import functionDiaryIcon from "./assets/icon/function_diary.png";
import functionLotteryIcon from "./assets/icon/function_lottery.png";
import functionTestingIcon from "./assets/icon/function_testing.png";
import functionTopicIcon from "./assets/icon/function_topic.png";
import functionWikiIcon from "./assets/icon/function_wiki.png";

import { changeBottomBar } from "reduxStore/store/actionCreators";
import { useDispatch } from "react-redux";

const HomeTopModelCard: React.FC<{ appDownloadTips: Function }> = ({ appDownloadTips }) =>{
  const dispatch = useDispatch();
  const history = useHistory();
  const [bannerAdvInfo, setBannerAdvInfo] = useState();
  const [bannerAdList, setBannerAdList] = useState([]);
  const [textAdvInfo, setTextAdvInfo] = useState();

  // 屏幕宽度
  const screenWidth = window.screen.availWidth;


  useEffect(() => {
    // 获取接口广告数据
    const loadAdData = async (apCode) => {
      return await mainApi.getAdvInfoByCode(apCode);
    }

    // 获取Text广告数据
    loadAdData('app_search_bottom_keyword').then((res) => {
      setTextAdvInfo(res);
    });

    // 获取Banner广告数据
    loadAdData('adv_home').then((res) => {
      setBannerAdvInfo(res);
      setBannerAdList(res.adv_list);
    });
  },[])



  // 渲染功能宫格导航块
  const renderFunctionNav = useCallback(() => {
    const title = '功能提示';
    const msg = '网页端暂不支持该功能，请下载APP后再使用';
    return (
      <div className={bannerAdList.length === 0 ? "home-function-block gradient-bg-color" : "home-function-block"}>
        {/* 训练课程 */}
        <div className="function-item" onClick={()=>{
          history.push(`/trains`);
          dispatch(changeBottomBar('trains'));
        }}>
          <div className="item-avatar" style={{backgroundImage: 'url('+functionTrainingIcon+')'}}></div>
          <div className="item-name">训练课程</div>
        </div>

        {/* 咨询医生 */}
        <div className="function-item" onClick={()=>{
          history.push(`/doctors`);
        }}>
          <div className="item-avatar" style={{backgroundImage: 'url('+functionDoctorIcon+')'}}></div>
          <div className="item-name">咨询医生</div>
        </div>

        {/* 健康自测 */}
        <div className="function-item" onClick={() => {
          history.push('/health-test')
        }}>
          <div className="item-avatar" style={{backgroundImage: 'url('+functionTestingIcon+')'}}></div>
          <div className="item-name">健康自测</div>
        </div>

        {/* 科普专栏 */}
        <div className="function-item" onClick={()=>{
          history.push(`/specials`);
        }}>
          <div className="item-avatar" style={{backgroundImage: 'url('+functionSpecialIcon+')'}}></div>
          <div className="item-name">科普专栏</div>
        </div>

        {/* 健康百科 */}
        <div className="function-item" onClick={() => appDownloadTips(title, msg)}>
          <div className="item-avatar" style={{backgroundImage: 'url('+functionWikiIcon+')'}}></div>
          <div className="item-name">健康百科</div>
        </div>

        {/* 丁丁圈子 */}
        <div className="function-item" onClick={()=>{
          history.push(`/topic`);
        }}>
          <div className="item-avatar" style={{backgroundImage: 'url('+functionTopicIcon+')'}}></div>
          <div className="item-name">丁丁圈子</div>
        </div>

        {/* 三甲男科 */}
        <div className="function-item" onClick={()=>{
          history.push(`/hospitallrule`);
        }}>
          <div className="item-avatar" style={{backgroundImage: 'url('+functionHospitalIcon+')'}}></div>
          <div className="item-name">三甲男科</div>
        </div>

        {/* 私密日记 */}
        <div className="function-item" onClick={() => appDownloadTips(title, msg)}>
          <div className="item-avatar" style={{backgroundImage: 'url('+functionDiaryIcon+')'}}></div>
          <div className="item-name">私密日记</div>
        </div>

        {/* 福利抽奖 */}
        <div className="function-item" onClick={() => appDownloadTips(title, msg)}>
          <div className="item-avatar" style={{backgroundImage: 'url('+functionLotteryIcon+')'}}></div>
          <div className="item-name">福利抽奖</div>
        </div>
      </div>
    );
  },[appDownloadTips, bannerAdList.length, dispatch, history]);

  return (
    <div className="home-top-model-block">

     {textAdvInfo && <div className="top-text-adv">
      <AdvCardView data={textAdvInfo} viewWidth={screenWidth} />
      </div>}

     {(bannerAdvInfo && bannerAdList.length > 0) && <div className="top-banner-adv">
        <div style={{
          margin: "10px 10px 0",
          overflow: "hidden"
        }}>
          <AdvCardView data={bannerAdvInfo} viewWidth={screenWidth - 20} />
        </div>
      </div>}

      {renderFunctionNav()}
    </div>
  );
}

export default HomeTopModelCard;
